import React, { useEffect, useState } from "react";
import { differenceInDays } from "date-fns";

// reactstrap components
import { Table, Row, Modal, ModalBody, Col } from "reactstrap";
import { useAuth } from "../../../context/AuthContext";
import formatCompleteDate from "../../../services/formatCompleteDate";
import formatPhoneString from "../../../services/formatPhoneString";
import formatDate from "../../../services/formatDate";

import api from "services/api";

function NotClientModal({ purchase, modal, toggle }) {
  const { user } = useAuth();
  const token = localStorage.getItem("@clube:token");
  const settings = user.settings[0];
  const cashbackModel = settings?.model === "cashback" ? true : false;

  const [tabSelected, setTabSelected] = useState(1);

  const [client, setClient] = useState(null);

  async function getClient() {
    const client = await api.get(`/store/clients/${purchase.phone}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setClient(client.data);
  }

  useEffect(() => {
    if (modal) {
      getClient();
    }
  }, [modal]);

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalBody
        style={{
          borderRadius: "30px",
          backgroundColor: "#fff",
        }}
      >
        {client && (
          <>
            <Row>
              <Col md="9">
                <h3>Cliente sem cadastro</h3>
              </Col>
              {/* <Col md="3" className="ml-auto" >
            <Button color="success">
                Enviar Promoção
            </Button>
          </Col> */}
            </Row>

            <Row style={{ paddingTop: 20 }}>
              <Col md="4">
                <strong>Telefone: </strong>
                {client.purchases[0].phone}
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col md="12">
                {settings?.model === "cashback" ? (
                  <strong style={{ color: "green" }}>
                    Cashback Disponível: R$
                    {(client.totalClientCashback !== null
                      ? parseFloat(client.totalClientCashback)
                      : 0
                    ).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                  </strong>
                ) : (
                  <strong style={{ color: "green" }}>
                    Pontos Disponíveis: {client.client[0]?.points} pontos
                  </strong>
                )}
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col md="12">
                <strong>Produto Favorito: </strong>{" "}
                {client?.allProducts ? client.allProducts[0]?.name : "-"}
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col>
                <strong>Gasto em seu estabelecimento: </strong> R$
                {client.purchases
                  .reduce(
                    (acc, purchase) => acc + parseFloat(purchase.total),
                    0
                  )
                  .toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
              </Col>
            </Row>
            <Row style={{ paddingTop: 10 }}>
              <Col>
                <strong>Comprou em seu estabelecimento: </strong>{" "}
                {client?.purchases.length} vez(es).
              </Col>
            </Row>
            <Row style={{ paddingTop: 10, paddingBottom: 20 }}>
              <Col>
                <strong>Próximo envio de SMS: </strong>{" "}
                {client?.daysUntilSms ? `em ${client?.daysUntilSms} dias` : "-"}
              </Col>
            </Row>

            <div style={{ display: "flex" }}>
              <h5
                style={{
                  padding: "5px 10px",
                  cursor: "pointer",
                  border: "1px solid",
                  borderRadius: 5,
                  borderColor: tabSelected === 1 ? "black" : "transparent",
                }}
                onClick={() => setTabSelected(1)}
              >
                <i className="nc-icon nc-user-run" /> Visitas
              </h5>
              <h5
                style={{
                  padding: "5px 10px",
                  cursor: "pointer",
                  border: "1px solid",
                  borderRadius: 5,
                  borderColor: tabSelected === 2 ? "black" : "transparent",
                }}
                onClick={() => setTabSelected(2)}
              >
                <i className="nc-icon nc-bag-16" /> Produtos
              </h5>
            </div>
            <Table>
              {tabSelected === 1 && (
                <>
                  <thead>
                    <tr>
                      <th>Valor Gasto</th>
                      <th>Produtos</th>
                      <th>Cashback</th>
                      <th className="text-center">Data</th>
                      <th className="text-right">Dias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {client?.purchases.map((purchase) => (
                      <tr>
                        <td>
                          R$
                          {parseFloat(purchase.total).toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td>{purchase.products?.length}</td>
                        <td>
                          {cashbackModel
                            ? `R$${(purchase.cashback !== null
                                ? parseFloat(purchase.cashback)
                                : 0
                              ).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                              })}`
                            : `${purchase.points} ponto(s)`}
                        </td>
                        <td className="text-center">
                          {formatCompleteDate(purchase.createdAt)}
                        </td>
                        <td className="text-right">
                          {differenceInDays(
                            new Date(),
                            new Date(purchase.createdAt)
                          ) > 0
                            ? `há ${differenceInDays(
                                new Date(),
                                new Date(purchase.createdAt)
                              )} dia(s)`
                            : "Hoje"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
              {tabSelected === 2 && (
                <>
                  <thead>
                    <tr>
                      <th>Produto</th>
                      <th>Quantidade de Compras</th>
                      <th className="text-center">Data da Última Compra</th>
                      <th className="text-right">Dias</th>
                    </tr>
                  </thead>
                  <tbody>
                    {client.allProducts?.map((product) => (
                      <tr>
                        <td>{product.name}</td>
                        <td className="text-center">{product.count}</td>
                        <td className="text-center">
                          {formatCompleteDate(product.last)}
                        </td>
                        <td className="text-right">
                          {differenceInDays(
                            new Date(),
                            new Date(product.last)
                          ) > 0
                            ? `há ${differenceInDays(
                                new Date(),
                                new Date(product.last)
                              )} dia(s)`
                            : "Hoje"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </Table>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default NotClientModal;
