import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Spinner,
} from "reactstrap";

import TableHistoryPurchase from "../TableHistoryPurchase";

function TableHistoryOrders({
  purchases,
  clients,
  notClients,
  pointsModel,
  infosLoading,
  purchasesLoading,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [filteredPurchases, setFilteredPurchases] = useState(null);
  function filterClients(search) {
    const unmask = search.replace("-", "");
    setFilteredPurchases(purchases.filter((p) => p.phone.startsWith(unmask)));
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Compras</CardTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Últimas 100 compras realizadas em seu estabelecimento.</p>

            <div>
              <span style={{ marginRight: 10 }}>Buscar telefone: </span>
              <ReactInputMask
                mask="(99)99999-9999"
                maskChar=""
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  filterClients(e.target.value);
                }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody
          style={{ overflowY: "scroll", height: "500px", paddingTop: 0 }}
        >
          <Table>
            <thead
              className="text-primary"
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#ffffff",
              }}
            >
              <tr style={{ fontSize: 14 }}>
                <th>Telefone</th>
                <th>Cliente</th>
                <th className="text-center">Valor da Compra</th>
                <th className="text-right">
                  {pointsModel ? "Pontos" : "Cashback"}
                </th>
                <th className="text-right">Resgate</th>
                <th className="text-right">Avaliação NPS</th>
                <th className="text-right">Data / Hora</th>
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            {purchasesLoading ? (
              <tbody>
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    <Spinner />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {filteredPurchases === null
                  ? purchases.map((purchase) => (
                      <TableHistoryPurchase
                        purchase={purchase}
                        clients={clients}
                        notClients={notClients}
                      />
                    ))
                  : filteredPurchases.map((purchase) => (
                      <TableHistoryPurchase
                        purchase={purchase}
                        clients={clients}
                        notClients={notClients}
                      />
                    ))}
              </tbody>
            )}
          </Table>
        </CardBody>
      </Card>
    </>
  );
}

export default TableHistoryOrders;
