import React, { useState, useEffect, useCallback, useRef } from "react";

import { Button, Row, Col } from "reactstrap";
// import { useHistory } from 'react-router-dom';

import NotificationAlert from "react-notification-alert";
import TableHistoryOrders from "./components/TableHistoryOrders";
import CardDash from "components/Cards/CardDash";
import api from "../services/api";
import { set } from "date-fns";

function Dashboard() {
  const formRef = useRef(null);
  const notificationAlert = useRef();
  // const history = useHistory();

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    if (notificationAlert.current) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  const token = localStorage.getItem("@clube:token");
  const [infosLoading, setInfosLoading] = useState(false);
  const [purchasesLoading, setPurchasesLoading] = useState(false);
  let [purchases, setPurchases] = useState([]);
  let [purchasesAmount, setPurchasesAmount] = useState([]);
  let [clients, setClients] = useState(0);
  let [notClients, setNotClients] = useState([]);
  let [total, setTotal] = useState("R$0,00");

  const getPurchasesInfos = useCallback(async () => {
    setInfosLoading(true);
    try {
      const response = await api.get("/store/purchases/infos", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPurchasesAmount(response.data.amount);
      const total = response.data.total
        ? parseFloat(response.data.total).toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          })
        : "0,00";
      setTotal(`R$${total}`);
      setClients(response.data.clients);
      setNotClients(response.data.notClients);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
    setInfosLoading(false);
  }, [token]);

  const getPurchases = useCallback(async () => {
    setPurchasesLoading(true);
    try {
      const response = await api.get("/store/purchases", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPurchases(response.data.purchases);

      setPurchasesLoading(false);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
      setPurchasesLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getPurchases();
    getPurchasesInfos();
  }, [getPurchases]);

  let displayClients = `${clients || "-"} / ${
    clients && notClients ? clients + notClients : "-"
  }`;

  function popitup(a) {
    window.open(
      a,
      "open_window",
      "height=3840, right=1, location=no, menubar=no, resizable=yes, scrollbars=yes, status=no, titlebar=no, toolbar=no, top=1, width=500,fullscreen=yes"
    );
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="8">
            <h2>
              <i className="nc-icon nc-refresh-69" /> Histórico de Compras
            </h2>
          </Col>
          <Col md="4">
            <Button
              onClick={() => popitup("https://operacao.takeat.club")}
              color="success"
              style={{ float: "right" }}
            >
              Abrir Operação
            </Button>
          </Col>
          {/* <Col md="7">
              <div style={{ float: "right" }}>
                <Button className="btn-round mr-auto" active color="info">
                  Hoje
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Essa semana
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Esse mês
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Selecionar Período
                </Button>
              </div>
            </Col> */}
        </Row>

        <Row style={{ marginTop: -15, marginBottom: 10 }}>
          <Col md="4">
            <Button onClick={() => getPurchases()} color="danger">
              Atualizar
            </Button>
          </Col>
        </Row>

        <Row>
          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Clientes Cadastrados / Total"
              total={displayClients}
              icon="nc-icon nc-single-02 text-info"
              loading={infosLoading}
            />
          </Col>
          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Arrecadação"
              total={total}
              icon="nc-icon nc-money-coins text-success"
              loading={infosLoading}
            />
          </Col>
          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Compras"
              total={purchasesAmount?.toLocaleString("pt-br")}
              icon="nc-icon nc-bag-16 text-danger"
              loading={infosLoading}
            />
          </Col>
        </Row>

        <Row md="12">
          <Col md="12">
            <TableHistoryOrders
              purchases={purchases}
              clients={clients}
              notClients={notClients}
              infosLoading={infosLoading}
              purchasesLoading={purchasesLoading}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
