import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";
import ClientsTable from "../../components/ClientsTable";
import NotClientsTable from "../../components/NotClientsTable";
import CardDash from "components/Cards/CardDash";
import { ButtonTab } from "../Reports/styles";
import { useAuth } from "../../../context/AuthContext";
import BirthdaysTable from "views/components/BirthdaysTable";

function Clients() {
  const notificationAlert = useRef();
  const token = localStorage.getItem("@clube:token");
  const [firstLoad, setFirstLoad] = useState(true);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    if (notificationAlert.current) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  const [clientsLoading, setClientsLoading] = useState(false);
  const [notClientsLoading, setNotClientsLoading] = useState(false);

  const [clients, setClients] = useState([]);
  const [clientsBirthdays, setClientsBirthdays] = useState([]);
  const [notClients, setNotClients] = useState([]);
  const [delta, setDelta] = useState({
    delta: 0,
    allMonth: true,
  });
  const [buttonTab, setButtonTab] = useState(1);
  const [birthdaysLoading, setBirthdaysLoading] = useState(false);
  const [btnSelected, setBtnSelected] = useState("month");

  const { user } = useAuth();
  const settings = user.settings[0];
  const cashbackModel = settings?.model === "cashback" ? true : false;

  const getClients = useCallback(async () => {
    try {
      setClientsLoading(true);
      const responseClient = await api.get("/store/clients/all", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setClients(responseClient.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar clientes cadastrados.");
    }
    setClientsLoading(false);
  }, [token]);

  const getNotClients = useCallback(async () => {
    try {
      setNotClientsLoading(true);
      const responseClient = await api.get("/store/not-clients/all", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNotClients(responseClient.data);
    } catch (err) {
      console.log("err > ", err);
      notify("tr", "danger", "Erro ao carregar não cadastrados.");
    }
    setNotClientsLoading(false);
  }, [token]);

  const getBirthdays = async () => {
    setBirthdaysLoading(true);
    const response = await api.get("/store/reports/birthdays", {
      headers: { Authorization: `Bearer ${token}` },
      params: delta,
    });

    setClientsBirthdays(response.data);
    setBirthdaysLoading(false);
  };

  useEffect(() => {
    if ((buttonTab === 2 || buttonTab === 3) && firstLoad) {
      getClients();
      getNotClients();
      setFirstLoad(false);
    }
  }, [buttonTab]);

  useEffect(() => {
    if (buttonTab === 1) {
      getBirthdays();
    }
  }, [delta]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="9">
            <h2>
              <i className="nc-icon nc-single-02" /> Clientes
            </h2>
          </Col>
        </Row>

        <Row style={{ marginBottom: 20 }}>
          <Col md="12">
            <ButtonTab
              thisButton={1}
              activeButton={buttonTab}
              onClick={() => setButtonTab(1)}
            >
              Aniversariantes
            </ButtonTab>
            <ButtonTab
              thisButton={2}
              activeButton={buttonTab}
              onClick={() => setButtonTab(2)}
            >
              Cadastrados
            </ButtonTab>
            <ButtonTab
              thisButton={3}
              activeButton={buttonTab}
              onClick={() => setButtonTab(3)}
            >
              Não cadastrados
            </ButtonTab>
          </Col>
        </Row>
        {(buttonTab === 2 || buttonTab === 3) && (
          <Row>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Todos os Clientes"
                total={(clients.length + notClients.length).toLocaleString(
                  "pt-br"
                )}
                icon="nc-icon nc-single-02 text-info"
                loading={clientsLoading || notClientsLoading}
              />
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes Cadastrados"
                total={clients.length.toLocaleString("pt-br")}
                icon="nc-icon nc-check-2 text-success"
                loading={clientsLoading}
              />
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes Sem Cadastrado"
                total={notClients.length.toLocaleString("pt-br")}
                icon="nc-icon nc-simple-remove text-danger"
                loading={notClientsLoading}
              />
            </Col>
          </Row>
        )}

        {buttonTab === 1 && (
          <>
            <Row>
              <Col>
                <div>
                  <Button
                    style={{
                      backgroundColor: btnSelected === "today" && "black",
                    }}
                    onClick={() => {
                      setDelta({ delta: 0 });
                      setBtnSelected("today");
                    }}
                  >
                    Hoje
                  </Button>
                  <Button
                    style={{
                      backgroundColor: btnSelected === "tomorrow" && "black",
                    }}
                    onClick={() => {
                      setDelta({
                        delta: 1,
                      });
                      setBtnSelected("tomorrow");
                    }}
                  >
                    Amanhã
                  </Button>
                  <Button
                    style={{
                      backgroundColor: btnSelected === "seven" && "black",
                    }}
                    onClick={() => {
                      setDelta({
                        delta: 0,
                        end: 7,
                      });
                      setBtnSelected("seven");
                    }}
                  >
                    Próximos 7 dias
                  </Button>
                  <Button
                    style={{
                      backgroundColor: btnSelected === "month" && "black",
                    }}
                    onClick={() => {
                      setDelta({
                        delta: 0,
                        allMonth: true,
                      });
                      setBtnSelected("month");
                    }}
                  >
                    Mês
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Aniversariantes</h4>
              </Col>
            </Row>

            <Row md="12">
              <Col md="12">
                <BirthdaysTable
                  clients={clientsBirthdays}
                  birthdaysLoading={birthdaysLoading}
                />
              </Col>
            </Row>
          </>
        )}
        {buttonTab === 2 && (
          <>
            <Row>
              <Col md="9">
                <h4>Clientes Cadastrados</h4>
              </Col>
            </Row>

            <Row md="12">
              <Col md="12">
                <ClientsTable
                  clients={clients}
                  cashbackModel={cashbackModel}
                  loading={clientsLoading}
                />
              </Col>
            </Row>
          </>
        )}

        {buttonTab === 3 && (
          <>
            <Row>
              <Col md="9">
                <h4>Clientes Não Cadastrados</h4>
              </Col>
            </Row>

            <Row md="12">
              <Col md="12">
                <NotClientsTable
                  clients={notClients}
                  cashbackModel={cashbackModel}
                  loading={notClientsLoading}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default Clients;
