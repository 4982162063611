import styled from "styled-components";
import { Link } from "react-router-dom";

export const LinkStyled = styled(Link)`
  text-decoration: none;
  width: 100%;
  border: none;
  &:hover {
    text-decoration: none;
  }

  &:focus {
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }
`;
export const PieChartDiv = styled.div`
  height: calc(100% - 20px);
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  align-items: ${(props) => (props.loading ? "center" : "")};
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  & + div {
    margin-left: 15px;
  }
`;

export const ChartButtons = styled.button`
  padding: 5px 10px;
  border: 1px solid #ff2c3a;
  background-color: white;
  border-radius: 5px;
  box-shadow: ${(props) =>
    props.children === props.label ? "none" : "-2px 2px 0px 0px #FF2C3A"};
  transition: all 0.3s;
  transform: ${(props) =>
    props.children === props.label
      ? "translate(0px, 0px)"
      : "translate(2px, -2px)"};

  :hover {
    background-color: #f0f0f0;
  }

  :focus {
    outline: none;
  }
`;

export const PieChartDivPromotion = styled.div`
  height: 250px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  & + div {
    margin-left: 15px;
  }
`;

export const ButtonTab = styled(Link)`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 10px 30px 10px 30px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 40px;

  ${({ thisButton, activeButton }) =>
    activeButton === thisButton &&
    `
    border-bottom: red 3px solid;
  `}

  :hover {
    border-bottom: red 2px solid;
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }

  :focus {
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }
`;

export const DoughnutChartDiv = styled.div`
  height: 450px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  & + div {
    margin-left: 15px;
  }
`;

export const ChartsArea = styled.div`
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
`;

export const HeaderLegend = styled.div`
  width: 100%;
  height: 20px;
  color: #3d3d3d;
  margin-bottom: 10px;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  display: flex;
  first-child {
    border-right: 1px solid #dee2e6;
  }
`;

export const Ratings = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  & + div {
    margin-left: 15px;
  }
`;

export const PieChartPayments = styled.div`
  margin: auto;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  padding: 15px;
  text-align: left;
  height: calc(100% - 35px);
`;

export const PieChartPromotion = styled.div`
  margin: auto;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  padding: 15px;
  text-align: left;
`;

export const BarChartDiv = styled.div`
  background-color: #fff;
  border-radius: 7px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  padding: 15px;
  text-align: left;
  height: calc(100% - 35px);
`;

export const SmsCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
`;

export const SmsCardText = styled.div`
  text-align: center;
  flex: 1 1 0px;
  padding: 10px;

  &:first-child {
    border-right: 1px solid lightgrey;
  }

  p {
    font-size: 18px;

    span {
      font-size: 25px;
      color: rgba(1, 175, 255, 1);
      font-weight: bold;
    }
  }
`;
