import React from "react";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import NotClientRowTable from "../NotClientRowTable";

function NotClientsTable({ clients, cashbackModel, loading }) {
  return (
    <>
      <Card style={{ overflowY: "scroll", maxHeight: "500px" }}>
        <CardBody>
          <Table className="table-shopping">
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#ffffff",
                zIndex: 1,
              }}
            >
              <tr style={{ fontSize: 13 }}>
                <th>Número </th>
                <th className="text-right">Cashback Disponível</th>
                <th className="text-right">Compras</th>
                <th className="text-right">Última Compra</th>
                <th />
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6} style={{ textAlign: "center" }}>
                    <Spinner />
                  </td>
                </tr>
              ) : (
                clients.map((client) => (
                  <NotClientRowTable
                    client={client}
                    cashbackModel={cashbackModel}
                  />
                ))
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
}

export default NotClientsTable;
