import React, { useState, useEffect, useCallback, useRef } from "react";
import { differenceInMinutes, subMonths } from "date-fns";

import { Row, Col, Button, Modal, ModalBody, Table, Spinner } from "reactstrap";

import {
  PieChartDiv,
  HeaderLegend,
  PieChartPayments,
  BarChartDiv,
  ButtonTab,
  LinkStyled,
  ChartButtons,
  SmsCard,
  SmsCardText,
} from "./styles";

import api from "../../../services/api";
import NotificationAlert from "react-notification-alert";
import LineChart from "../../components/Reports/LineChart";
import LineFullChart from "../../components/Reports/LineFullChart";
import PieChart from "../../components/Reports/PieChart";
import BarChart from "../../components/Reports/BarChart";
import DoughnutChart from "../../components/Reports/DoughnutChart";
import RatingComponent from "../../components/RatingComponent";
import PromotionsReport from "../../components/PromotionsReport";
import ProductsReport from "../../components/ProductsReport";
import formatCompleteDate from "../../../services/formatCompleteDate";
import formatDate from "../../../services/formatDate";
import formatPhoneString from "../../../services/formatPhoneString";
import formatToMonth from "../../../services/formatToMonth";
import formatToYear from "../../../services/formatToYear";
import CardDash from "components/Cards/CardDash";
import { BsPersonCheck } from "react-icons/bs";
import { MdPersonPinCircle, MdInfo } from "react-icons/md";

import Funnel, {
  Title,
  Margin,
  Tooltip,
  Item,
  Border,
  Label,
} from "devextreme-react/funnel";
import { useAuth } from "../../../context/AuthContext";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useReports } from "context/ReportsContext";
import { Loader } from "components/Loader";
import { FaCoins, FaMoneyBillWave } from "react-icons/fa";

function Reports() {
  const notificationAlert = useRef();

  const token = localStorage.getItem("@clube:token");
  const { user } = useAuth();
  const { setCache, getCache } = useReports();

  const settings = user.settings[0];
  const pointsModel = settings?.model === "points" ? true : false;

  const [buttonTab, setButtonTab] = useState(1);

  const ordersData = getCache("ordersData");
  const [ordersValueMonth, setOrdersValueMonth] = useState(
    ordersData?.orders_months || []
  );
  const [totalPriceMonth, setTotalPriceMonth] = useState(
    ordersData?.total_price || []
  );
  const [totalRescueMonth, setTotalRescueMonth] = useState(
    ordersData?.total_rescues || []
  );
  const [totalPointsMonth, setTotalPointsMonth] = useState(
    ordersData?.total_points || []
  );

  const [bairrosLabel, setBairrosLabel] = useState(
    getCache("bairrosLabel") || []
  );
  const [bairrosData, setBairrosData] = useState(
    getCache("bairrosClients") || []
  );
  const [bairrosClients, setBairrosClients] = useState(
    getCache("bairrosClients") || []
  );
  const [bairrosTotal, setBairrosTotal] = useState(
    getCache("bairrosTotal") || []
  );
  const [graphLabel, setGraphLabel] = useState("Clientes");
  const [ages, setAges] = useState(getCache("ages") || []);
  const [gender, setGender] = useState(getCache("gender") || []);
  const [rate, setRate] = useState(getCache("rate") || []);

  const [ratingLoading, setRatingLoading] = useState(false);
  const [genderLoading, setGenderLoading] = useState(false);
  const [ageLoading, setAgeLoading] = useState(false);
  const [activeLoading, setActiveLoading] = useState(false);
  const [earningsLoading, setEarningsLoading] = useState(false);
  const [loyalLoading, setLoyalLoading] = useState(false);
  const [totalsLoading, setTotalsLoading] = useState(false);
  const [bairrosLoading, setBairrosLoading] = useState(false);
  const [registeredLoading, setRegisteredLoading] = useState(false);

  const [percentClients, setPercentClients] = useState(
    getCache("percentClients") || []
  );
  const [dataClients, setDataClients] = useState(getCache("dataClients") || []);

  const genderData = getCache("genderData");
  const [percentGender, setPercentGender] = useState(genderData?.percent || []);
  const [phraseGender, setPhraseGender] = useState(genderData?.phrase || "");
  const [styleGender, setStyleGender] = useState(genderData?.style || {});

  const [clientsValueMonth, setClientsValueMonth] = useState(
    getCache("monthData") || []
  );
  const [clientsMonth, setClientsMonth] = useState(
    getCache("clientsMonth") || []
  );
  const [clientsMonthLoading, setClientsMonthLoading] = useState(false);

  const [promotionReport, setPromotionReport] = useState([]);
  const [productReport, setProductReport] = useState([]);

  const smsData = getCache("back");
  const [smsAmount, setSmsAmount] = useState(smsData?.amount || {});
  const [smsEarnings, setSmsEarnings] = useState(smsData?.earnings || {});
  const [smsLoading, setSmsLoading] = useState(false);
  const smsMonth = subMonths(new Date(), 1).toLocaleString("pt-br", {
    month: "long",
  });

  const getGrouped = useCallback(async () => {
    setTotalsLoading(true);
    const response = await api.get(
      `/store/reports/grouped/purchases?last_get=${lastGet}`,
      {
        params: {
          period: "month",
          timezone: 180,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    let totalPriceData;

    if (lastGet !== null) {
      let newTotalPrice = totalPriceMonth;
      let newPointsData = totalPointsMonth;

      response.data.forEach((order) => {
        const month = `${formatToMonth(order.created_at)} /${formatToYear(
          order.created_at
        )}`;
        const index = ordersValueMonth.indexOf(month);
        newTotalPrice[index] += order.total;
        newPointsData[index] += order.points;
      });

      setTotalPriceMonth(newTotalPrice);
      setTotalPointsMonth(newPointsData);
      setCache("ordersData", {
        orders_months: ordersValueMonth,
        total_points: newPointsData,
        total_price: newTotalPrice,
      });
    } else {
      totalPriceData = response.data?.map((obj) => obj.total);

      const totalPointsData = response.data?.map((obj) => obj.points);

      const monthData = response.data.map(
        (obj) =>
          `${formatToMonth(obj.created_at)} /${formatToYear(obj.created_at)}`
      );

      const responseRescue = await api.get("/store/reports/grouped/rescues", {
        params: {
          period: "month",
          timezone: 180,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      const totalRescueData = responseRescue.data.grouped.map(
        (obj) => obj.points
      );

      setOrdersValueMonth(monthData);
      setTotalPriceMonth(totalPriceData);
      setTotalRescueMonth(totalRescueData);
      setTotalPointsMonth(totalPointsData);

      setCache("ordersData", {
        orders_months: monthData,
        total_price: totalPriceData,
        total_rescues: totalRescueData,
        total_points: totalPointsData,
      });
    }
    setTotalsLoading(false);
  }, []);

  const [lastGet, setLastGet] = useState(getCache("lastGet"));

  const getClient = useCallback(async () => {
    try {
      setAgeLoading(true);
      setGenderLoading(true);
      setBairrosLoading(true);
      setClientsMonthLoading(true);
      setRegisteredLoading(true);
      const response = await api.get(
        `store/reports/clients?last_get=${lastGet}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (lastGet !== null) {
        let newClientsMonth = clientsMonth;

        const newMonthData = response.data.clients.map((obj) => {
          return {
            count: obj.count,
            month: `${formatToMonth(obj.created_at)} /${formatToYear(
              obj.created_at
            )}`,
          };
        });

        newMonthData.forEach((month) => {
          const index = clientsValueMonth.indexOf(month.month);
          newClientsMonth[index] = newClientsMonth[index] + month.count;
        });

        setClientsMonth(newClientsMonth);
        setCache("clientsMonth", newClientsMonth);
        setClientsMonthLoading(false);

        const ages = JSON.parse(localStorage.getItem("@clube:ages"));
        const newAges = response.data.birthday.map(
          (bday, index) => ages[index] + bday.count
        );
        setAges(newAges);
        setCache("ages", newAges);
        setAgeLoading(false);

        let newBairrosClients = bairrosClients;
        let newBairrosTotal = bairrosTotal;
        let newBairrosLabel = getCache("bairrosLabel");
        response.data.bairro.forEach((bairro) => {
          const index = newBairrosLabel.indexOf(bairro.name);

          if (index !== -1) {
            newBairrosClients[index] += bairro.count;
            newBairrosTotal[index] += bairro.total;

            setBairrosClients(newBairrosClients);
            setCache("bairrosClients", newBairrosClients);
            setBairrosTotal(newBairrosTotal);
            setCache("bairrosTotal", newBairrosTotal);
            setBairrosData(newBairrosClients);
          } else {
            newBairrosLabel.push(bairro.name);
            newBairrosClients.push(bairro.count);
            newBairrosTotal.push(bairro.total);
          }
        });
        let bairrosToSort = [];
        for (let i = 0; i < newBairrosLabel.length; i++) {
          bairrosToSort.push({
            name: newBairrosLabel[i],
            count: newBairrosClients[i],
            total: newBairrosTotal[i],
          });
        }
        const sortedBairros = bairrosToSort
          .sort((a, b) => b.count - a.count)
          .slice(0, 10);
        setBairrosLabel(sortedBairros.map((b) => b.name));
        setCache(
          "bairrosLabel",
          sortedBairros.map((b) => b.name)
        );
        setBairrosClients(sortedBairros.map((b) => b.count));
        setCache(
          "bairrosClients",
          sortedBairros.map((b) => b.count)
        );
        setBairrosTotal(sortedBairros.map((b) => b.total));
        setCache(
          "bairrosTotal",
          sortedBairros.map((b) => b.total)
        );
        setBairrosData(sortedBairros.map((b) => b.count));
        setBairrosLoading(false);
      } else {
        const bairrosSorted = response.data.bairro
          .sort((a, b) => b.count - a.count)
          .slice(0, 10);
        const bairrosLabel = bairrosSorted.map((bairro) => bairro.name);

        setBairrosClients(bairrosSorted.map((bairro) => bairro.count));
        setCache(
          "bairrosClients",
          bairrosSorted.map((bairro) => bairro.count)
        );

        setBairrosTotal(bairrosSorted.map((bairro) => bairro.total));
        setCache(
          "bairrosTotal",
          bairrosSorted.map((bairro) => bairro.total)
        );
        setBairrosData(bairrosSorted.map((bairro) => bairro.count));

        const gender = response.data.gender.map((gender) => gender.count);
        let genderTotal = 0;

        response.data.gender.forEach((gender) => {
          genderTotal = genderTotal + gender.count;
        });

        const genderM = gender[0];
        const genderF = gender[1];

        let percentM = (genderM / genderTotal) * 100;
        percentM = parseInt(percentM);
        let percentF = (genderF / genderTotal) * 100;
        percentF = parseInt(percentF);
        let percentO = 100 - percentF - percentM;

        if (percentM > percentF) {
          setPercentGender(percentM);
          setPhraseGender("do seu público alvo são homens");
          setStyleGender({ color: "#3B8EA5" });
          setCache("genderData", {
            percent: percentM,
            phrase: "do seu público alvo são homens",
            style: { color: "#3B8EA5" },
          });
        } else if (percentF > percentM) {
          setPercentGender(percentF);
          setPhraseGender("do seu público alvo são mulheres");
          setStyleGender({ color: "#e83e8c" });
          setCache("genderData", {
            percent: percentF,
            phrase: "do seu público alvo são mulheres",
            style: { color: "#e83e8c" },
          });
        } else {
          if (percentF == percentM && percentF === percentO) {
            setPercentGender(percentO);
            setPhraseGender(
              "do seu público alvo são homens, mulheres e outros gêneros"
            );
            setStyleGender({ color: "#green" });
            setCache("genderData", {
              percent: percentO,
              phrase:
                "do seu público alvo são homens, mulheres e outros gêneros",
              style: { color: "#green" },
            });
          }
        }

        if (percentO > percentF && percentO > percentM) {
          setPercentGender(percentO);
          setPhraseGender("do seu público alvo são de outro gênero");
          setStyleGender({ color: "#green" });
          setCache("genderData", {
            percent: percentO,
            phrase: "do seu público alvo são de outro gênero",
            style: { color: "#green" },
          });
        }

        const ages = response.data.birthday.map((birthday) => birthday.count);
        const monthData = response.data.clients.map((obj) => {
          return `${formatToMonth(obj.created_at)} /${formatToYear(
            obj.created_at
          )}`;
        });

        const clientsData = response.data.clients.map((obj) => obj.count);

        setClientsMonth(clientsData);
        setCache("clientsMonth", clientsData);
        setClientsMonthLoading(false);

        setClientsValueMonth(monthData);
        setCache("monthData", monthData);

        setAges(ages);
        setCache("ages", ages);
        setAgeLoading(false);

        setGender(gender);
        setCache("gender", gender);
        setGenderLoading(false);

        setBairrosLabel(bairrosLabel);
        setCache("bairrosLabel", bairrosLabel);
        setBairrosLoading(false);
      }
    } catch (err) {
      // alert("Erro ao carregar informações");
      notify("tr", "danger", "Erro ao carregar informações");
    }
  }, []);

  const getMonthly = async () => {
    const responseMonthly = await api.get(
      `/store/clients/monthly?last_get=${lastGet}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (lastGet !== null) {
      const clients = dataClients[0] + responseMonthly.data.clients;
      const notClients = dataClients[1] + responseMonthly.data.notClients;
      const allClients = clients + notClients;
      const percentClients = parseInt((clients / allClients) * 100);

      setDataClients([clients, notClients]);
      setCache("dataClients", [clients, notClients]);
      setPercentClients(percentClients);
      setCache("percentClients", percentClients);
      setRegisteredLoading(false);
    } else {
      const clients = responseMonthly.data.clients;
      const notClients = responseMonthly.data.notClients;
      const allClients = clients + notClients;
      let percentClients = (clients / allClients) * 100;
      percentClients = parseInt(percentClients);

      setDataClients([clients, notClients]);
      setCache("dataClients", [clients, notClients]);

      setPercentClients(percentClients);
      setCache("percentClients", percentClients);
      setRegisteredLoading(false);
    }
  };

  const getRescues = useCallback(async () => {
    try {
      const response = await api.get("store/reports/promotions/rescues", {
        headers: { Authorization: `Bearer ${token}` },
      });

      let toReturn = [];
      let ranking = 1;
      response.data.forEach((data) => {
        const bairrosLabel = data.client.bairro.map((bairro) => bairro.name);

        const bairrosData = data.client.bairro.map((bairro) => bairro.count);

        const gender = data.client.gender.map((gender) => gender.count);

        let genderTotal = 0;

        data.client.gender.forEach((gender) => {
          genderTotal = genderTotal + gender.count;
        });

        const genderM = gender[0];
        const genderF = gender[1];

        let percentM = (genderM / genderTotal) * 100;
        percentM = parseInt(percentM);
        let percentF = (genderF / genderTotal) * 100;
        percentF = parseInt(percentF);
        let percentO = 100 - percentF - percentM;

        let percentGender = 0;
        let phraseGender = "";
        let styleGender = null;

        if (percentM > percentF) {
          percentGender = percentM;
          phraseGender = "do seu público alvo são homens";
          styleGender = { color: "#3B8EA5" };
        } else if (percentF > percentM) {
          percentGender = percentF;
          phraseGender = "do seu público alvo são mulheres";
          styleGender = { color: "#e83e8c" };
        } else {
          if (percentF == percentM && percentF === percentO) {
            percentGender = percentO;
            phraseGender =
              "do seu público alvo são homens, mulheres e outros gêneros";
            styleGender = { color: "#green" };
          }
        }

        if (percentO > percentF && percentO > percentM) {
          percentGender = percentO;
          phraseGender = "do seu público alvo são de outro gêneros";
          styleGender = { color: "#green" };
        }
        const ages = data.client.birthday.map((birthday) => birthday.count);

        const monthData = data.lastYear.map(
          (obj) =>
            `${formatToMonth(obj.created_at)} /${formatToYear(obj.created_at)}`
        );

        const rescueData = data.lastYear.map((obj) => obj.count);

        toReturn.push({
          promotion: data.promotion,
          ranking,
          percent: data.percent,
          count: data.count,
          ages,
          gender,
          percentGender,
          phraseGender,
          styleGender,
          bairrosLabel,
          bairrosData,
          monthData,
          rescueData,
        });

        ranking++;
      });

      setPromotionReport(toReturn);
    } catch (err) {
      notify("tr", "danger", err);
    }
  }, []);

  const getProducts = useCallback(async () => {
    try {
      const response = await api.get("store/reports/products/rescues", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let toReturn = [];
      let ranking = 1;
      response.data.forEach((data) => {
        const bairrosLabel = data.client.bairro.map((bairro) => bairro.name);

        const bairrosData = data.client.bairro.map((bairro) => bairro.count);
        const gender = data.client.gender.map((gender) => gender.count);

        let genderTotal = 0;

        data.client.gender.forEach((gender) => {
          genderTotal = genderTotal + gender.count;
        });

        const genderM = gender[0];
        const genderF = gender[1];
        const genderO = gender[2];

        let percentM = (genderM / genderTotal) * 100;
        percentM = parseInt(percentM);
        let percentF = (genderF / genderTotal) * 100;
        percentF = parseInt(percentF);
        let percentO = 100 - percentF - percentM;

        let percentGender = 0;
        let phraseGender = "";
        let styleGender = null;

        if (percentM > percentF) {
          percentGender = percentM;
          phraseGender = "do seu público alvo são homens";
          styleGender = { color: "#3B8EA5" };
        } else if (percentF > percentM) {
          percentGender = percentF;
          phraseGender = "do seu público alvo são mulheres";
          styleGender = { color: "#e83e8c" };
        } else {
          if (percentF == percentM && percentF === percentO) {
            percentGender = percentO;
            phraseGender =
              "do seu público alvo são homens, mulheres e outros gêneros";
            styleGender = { color: "#green" };
          }
        }

        if (percentO > percentF && percentO > percentM) {
          percentGender = percentO;
          phraseGender = "do seu público alvo são de outro gêneros";
          styleGender = { color: "#green" };
        }

        const ages = data.client.birthday.map((birthday) => birthday.count);

        const monthData = data.lastYear.map(
          (obj) =>
            `${formatToMonth(obj.created_at)} /${formatToYear(obj.created_at)}`
        );

        const rescueData = data.lastYear.map((obj) => obj.count);

        toReturn.push({
          product: data.product,
          ranking,
          percent: data.percent,
          count: data.count,
          ages,
          gender,
          percentGender,
          phraseGender,
          styleGender,
          bairrosLabel,
          bairrosData,
          monthData,
          rescueData,
        });

        ranking++;
      });

      setProductReport(toReturn);
    } catch (err) {
      notify("tr", "danger", err);
    }
  }, []);

  const getRate = useCallback(async () => {
    try {
      setRatingLoading(true);
      const response = await api.get("store/ratings", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRate(response.data);
      setCache("rate", response.data);
      setRatingLoading(false);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações");
    }
  }, []);

  const [newClientsLastMonth, setNewClientsLastMonth] = useState(0);
  const [totalAllPurchases, setTotalAllPurchases] = useState(
    getCache("totalAllPurchases") || 0
  );
  const [clientsAtivos, setClientsAtivos] = useState(
    getCache("activeClients") || 0
  );
  const [clientsFieis, setClientsFieis] = useState(
    getCache("loyalClients") || 0
  );

  const [funilData, setFunilData] = useState(getCache("dataToChart") || []);
  const [modalData30, setModalData30] = useState(getCache("last30days") || []);
  const [modalData60, setModalData60] = useState(
    getCache("last30to60days") || []
  );
  const [modalData90, setModalData90] = useState(
    getCache("last60to90days") || []
  );
  const [modalDataFieis, setModalDataFieis] = useState([]);

  const getNewClients = useCallback(async () => {
    const newClients = await api.get("/store/reports/new-clients", {
      headers: { Authorization: `Bearer ${token}` },
    });

    setNewClientsLastMonth(newClients.data);
  }, []);

  const getActiveClients = useCallback(async () => {
    setActiveLoading(true);
    const activeClients = await api.get("/store/reports/active-clients", {
      headers: { Authorization: `Bearer ${token}` },
    });

    setClientsAtivos(activeClients.data);
    setCache("activeClients", activeClients.data);
    setActiveLoading(false);
  }, []);

  const getLoyalClients = useCallback(async () => {
    setLoyalLoading(true);
    const loyalClients = await api.get("/store/reports/loyal-clients", {
      headers: { Authorization: `Bearer ${token}` },
    });

    setClientsFieis(loyalClients.data.clientsFieis);
    setCache("loyalClients", loyalClients.data.clientsFieis);
    setModalDataFieis(loyalClients.data.clientsFieisData);
    setLoyalLoading(false);
  }, []);

  const getAllPurchases = useCallback(async () => {
    setEarningsLoading(true);
    const allPurchases = await api.get(
      `/store/reports/purchases?last_get=${lastGet}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (lastGet === null) {
      setTotalAllPurchases(allPurchases.data);
      setCache("totalAllPurchases", allPurchases.data);
    } else {
      setTotalAllPurchases(
        parseFloat(totalAllPurchases) + parseFloat(allPurchases.data)
      );
      setCache(
        "totalAllPurchases",
        parseFloat(totalAllPurchases) + parseFloat(allPurchases.data)
      );
    }
    setEarningsLoading(false);
  }, []);

  const getFunilData = useCallback(async () => {
    setFunilDataLoading(true);
    const funil30 = await api.get(
      "/store/reports/clients-period?start=30&end=0",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setModalData30(funil30.data);
    setCache("last30days", funil30.data);
    const funil60 = await api.get(
      "/store/reports/clients-period?start=60&end=30",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let funil60Filtered = {
      clients: 0,
      lastPurchases: [],
      endDate: funil60.data.endDate,
      startDate: funil60.data.startDate,
    };
    funil60.data.lastPurchases.forEach((purchase) => {
      const exists = funil30.data.lastPurchases.find(
        (p) => p.client.phone === purchase.client.phone
      );
      if (!exists) {
        funil60Filtered.clients++;
        funil60Filtered.lastPurchases.push(purchase);
      }
    });
    setModalData60(funil60Filtered);
    setCache("last30to60days", funil60Filtered);
    const funil90 = await api.get(
      "/store/reports/clients-period?start=90&end=60",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let funil90Filtered = {
      clients: 0,
      lastPurchases: [],
      endDate: funil90.data.endDate,
      startDate: funil90.data.startDate,
    };
    setCache("last60to90days", funil90Filtered);
    setModalData90(funil90Filtered);
    funil90.data.lastPurchases.forEach((purchase) => {
      const exists30 = funil30.data.lastPurchases.find(
        (p) => p.client.phone === purchase.client.phone
      );
      const exists60 = funil60Filtered.lastPurchases?.find(
        (p) => p.client.phone === purchase.client.phone
      );
      if (!exists60 && !exists30) {
        funil90Filtered.clients++;
        funil90Filtered.lastPurchases.push(purchase);
      }
    });
    const funilData = [
      {
        argument: "até 30 dias",
        value: funil30.data.clients,
        parsedStartDate: funil30.data.startDate,
        parsedEndDate: funil30.data.endDate,
      },
      {
        argument: "30-60 dias",
        value: funil60Filtered.clients,
        parsedStartDate: funil60.data.startDate,
        parsedEndDate: funil60.data.endDate,
      },
      {
        argument: "60-90 dias",
        value: funil90Filtered.clients,
        parsedStartDate: funil90.data.startDate,
        parsedEndDate: funil90.data.endDate,
      },
    ];
    setFunilData(funilData);
    setCache("dataToChart", funilData);

    setFunilDataLoading(false);
  }, []);

  function formatLabel(arg) {
    return `<span class="label">${arg.item.value} clientes</span><br/>${arg.item.argument}`;
  }

  let [purchases, setPurchases] = useState([]);
  let [loadingReport, setLoadingReport] = useState(false);

  const getPurchases = useCallback(async () => {
    try {
      const response = await api.get("/store/purchases", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let purchasesFilteres = response.data.purchases
        .slice(0, 100)
        .map((i) => i);
      setPurchases(purchasesFilteres);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações");
    }
  }, [token]);

  const getBackAfterSms = useCallback(async () => {
    setSmsLoading(true);
    const response = await api.get("/store/sms/back", {
      headers: { Authorization: `Bearer ${token}` },
    });

    setSmsAmount(response.data.amount);
    setSmsEarnings(response.data.earnings);
    setCache("back", {
      amount: response.data.amount,
      earnings: response.data.earnings,
    });
    setSmsLoading(false);
  });

  useEffect(() => {
    if (differenceInMinutes(new Date(lastGet), new Date()) < -30) {
      getNewClients();
      getBackAfterSms();
      getLoyalClients();
      getActiveClients();
      getAllPurchases();
      getFunilData();
      getGrouped();
      getClient();
      getRate();
      getPurchases();
      getMonthly();
      setLastGet(JSON.stringify(new Date()));
      setCache("lastGet", new Date());
    }
    getProducts();
    getRescues();
  }, []);

  const [modal30, setModal30] = useState(false);
  const toggle30 = () => setModal30(!modal30);

  const [modal60, setModal60] = useState(false);
  const toggle60 = () => setModal60(!modal60);

  const [modal90, setModal90] = useState(false);
  const toggle90 = () => setModal90(!modal90);

  const [modalFieis, setModalFieis] = useState(false);
  const toggleFieis = () => setModalFieis(!modalFieis);

  const [loyalClientsLoading, setLoyalClientsLoading] = useState(false);
  const [activeClientsLoading, setActiveClientsLoading] = useState(false);
  const [allPurchasesLoading, setAllPurchasesLoading] = useState(false);
  const [funilDataLoading, setFunilDataLoading] = useState(false);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };

    if (notificationAlert.current) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  function titleize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
      var w = words[a];
      if (w[0] !== undefined) {
        words[a] = w[0].toUpperCase() + w.slice(1);
      }
    }
    return words.join(" ");
  }
  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="9">
            <h2>
              <i className="nc-icon nc-chart-bar-32" /> Relatórios
            </h2>
          </Col>
        </Row>

        {loadingReport ? (
          <Row>
            <Col md="12">
              <div style={{ textAlign: "center", marginTop: 30 }}>
                <Spinner color="danger" />
              </div>
            </Col>
          </Row>
        ) : (
          <div>
            <Row style={{ marginBottom: 20 }}>
              <Col md="12">
                <ButtonTab
                  thisButton={1}
                  activeButton={buttonTab}
                  onClick={() => setButtonTab(1)}
                >
                  Relatórios Gerais
                </ButtonTab>
                <ButtonTab
                  thisButton={2}
                  activeButton={buttonTab}
                  onClick={() => setButtonTab(2)}
                >
                  Ofertas
                </ButtonTab>
                <ButtonTab
                  thisButton={3}
                  activeButton={buttonTab}
                  onClick={() => setButtonTab(3)}
                >
                  Segmentações
                </ButtonTab>
              </Col>
            </Row>
            {buttonTab === 1 && (
              <div>
                <Row style={{ minHeight: "393px" }}>
                  <Col lg="7" md="6" sm="6">
                    <PieChartDiv>
                      <HeaderLegend>Clientes</HeaderLegend>
                      {clientsMonthLoading ? (
                        <Loader text="Essas informações podem demorar um pouco a carregar devido ao alto volume de dados" />
                      ) : (
                        <LineFullChart
                          data={{
                            labels: clientsValueMonth,
                            datasets: [
                              {
                                label: "Novos Clientes",
                                data: clientsMonth,
                                backgroundColor: "#01AFFF",
                                borderColor: "#01AFFF",
                                pointRadius: 0,
                                pointHoverRadius: 0,
                                borderWidth: 3,
                                tension: 0.5,
                              },
                            ],
                          }}
                        />
                      )}
                    </PieChartDiv>
                  </Col>
                  <Col lg="5" md="6" sm="6">
                    <RatingComponent
                      rate={rate}
                      ratingLoading={ratingLoading}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <SmsCard>
                      {smsLoading ? (
                        <div
                          style={{
                            height: "154px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "18px",
                              margin: 0,
                            }}
                          >
                            Efetividade da comunicação{" "}
                          </p>
                          <Loader height="calc(100% - 47px)" />
                        </div>
                      ) : (
                        <>
                          <SmsCardText>
                            <p style={{ fontWeight: "bold" }}>
                              Efetividade da comunicação -{" "}
                              <span
                                style={{
                                  color: "rgba(1, 175, 255, 1)",
                                  fontWeight: "bold",
                                }}
                              >
                                {smsMonth}
                              </span>
                            </p>
                            <p>
                              <span>{smsAmount.total}</span> clientes retornaram
                              em até 7 dias após receberem Whatsapp
                            </p>
                          </SmsCardText>
                          <SmsCardText>
                            <p style={{ fontWeight: "bold" }}>
                              Receita gerada por clientes que receberam Whatsapp
                            </p>
                            <p
                              style={{
                                fontSize: 25,
                                color: "rgba(1, 175, 255, 1)",
                                fontWeight: "bold",
                              }}
                            >
                              R${" "}
                              {smsEarnings.total?.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                              })}
                            </p>
                          </SmsCardText>
                        </>
                      )}
                    </SmsCard>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="6" sm="6">
                    <CardDash
                      title="Clientes Ativos"
                      total={`${clientsAtivos.toLocaleString("pt-br")}`}
                      icon={<MdPersonPinCircle color="#0CDCE8" />}
                      useIcon={true}
                      info={
                        <MdInfo
                          color="orange"
                          title="Clientes que consumiram nos últimos 3 meses no seu estabelecimento"
                        />
                      }
                      loading={activeLoading}
                    ></CardDash>
                  </Col>
                  <Col lg="4" md="6" sm="6">
                    <CardDash
                      title="Vendas Bonificadas"
                      total={`R$${parseFloat(totalAllPurchases).toLocaleString(
                        "pt-br",
                        { minimumFractionDigits: 2 }
                      )}`}
                      useIcon={true}
                      icon={<FaMoneyBillWave color="#FF2C3A" />}
                      fontSize={25}
                      loading={earningsLoading}
                    ></CardDash>
                  </Col>
                  <Col lg="4" md="6" sm="6">
                    <LinkStyled onClick={() => toggleFieis()}>
                      <CardDash
                        title="Clientes Fiéis"
                        total={`${clientsFieis?.toLocaleString("pt-br")}`}
                        icon={<BsPersonCheck color="#01AFFF" />}
                        useIcon={true}
                        info={
                          <MdInfo
                            color="orange"
                            title="Clientes que fizeram 3 ou mais compras nos últimos 90 dias"
                          />
                        }
                        loading={loyalLoading}
                      ></CardDash>
                    </LinkStyled>
                  </Col>
                </Row>
                <Row style={{ minHeight: "400px" }}>
                  <Col md="8">
                    <PieChartDiv style={{ height: "calc(100% - 20px)" }}>
                      <HeaderLegend>
                        Vendas (R$) / Pontos Gerados / Pontos Resgatados
                      </HeaderLegend>
                      {totalsLoading ? (
                        <Loader />
                      ) : (
                        <LineChart
                          data={{
                            labels: ordersValueMonth,
                            datasets: [
                              {
                                label: "Vendas (R$)",
                                data: totalPriceMonth,
                                backgroundColor: "#0CDCE8",
                                borderColor: "#0CDCE8",
                                borderWidth: 1,
                                tension: 0.4,
                              },
                              {
                                label: "Pontos Gerados",
                                data: totalPointsMonth,
                                backgroundColor: "#01AFFF",
                                borderColor: "#01AFFF",
                                borderWidth: 1,
                                tension: 0.4,
                              },
                              {
                                label: "Pontos Resgatados",
                                data: totalRescueMonth,
                                backgroundColor: "#FF2C3A",
                                borderColor: "#FF2C3A",
                                borderWidth: 1,
                                tension: 0.4,
                              },
                            ],
                          }}
                        />
                      )}
                    </PieChartDiv>
                  </Col>
                  <Col md="4">
                    <PieChartDiv>
                      <HeaderLegend style={{ marginTop: 10 }}>
                        Clientes Cadastrados
                      </HeaderLegend>
                      {registeredLoading ? (
                        <Loader height="calc(100% - 45px)" />
                      ) : (
                        <>
                          <div style={{ width: "60%", margin: "auto" }}>
                            <DoughnutChart
                              data={{
                                // labels: ['Clientes Cadastrados', 'Clientes Não Cadastrados'],
                                datasets: [
                                  {
                                    pointRadius: 0,
                                    pointHoverRadius: 0,
                                    backgroundColor: ["#01AFFF", "#f4f3ef"],
                                    borderWidth: 1,
                                    data: dataClients,
                                  },
                                ],
                              }}
                            />
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <h2 style={{ marginTop: 15, color: "#01AFFF" }}>
                              {percentClients || "-"}%
                            </h2>
                            <p style={{ marginTop: -15 }}>
                              dos clientes que compraram em seu estabelecimento,
                              participam do clube!
                            </p>
                          </div>
                        </>
                      )}
                    </PieChartDiv>
                  </Col>
                </Row>
                <Row style={{ minHeight: "472px" }}>
                  <Col md="4">
                    <PieChartPayments style={{ marginTop: 10 }}>
                      <HeaderLegend>Gênero de Seus Clientes</HeaderLegend>
                      {genderLoading ? (
                        <Loader height="calc(100% - 45px)" />
                      ) : (
                        <>
                          <PieChart
                            data={{
                              labels: ["Masculino", "Feminino", "Outros"],
                              datasets: [
                                {
                                  data: gender,
                                  backgroundColor: [
                                    "#01AFFF",
                                    "#FF2C3A",
                                    "#FFB800",
                                  ],
                                  tension: 0.4,
                                },
                              ],
                            }}
                          />
                          <div style={{ textAlign: "center" }}>
                            <h2 style={{ ...styleGender, marginTop: 15 }}>
                              {percentGender || "-"}%
                            </h2>
                            <p style={{ marginTop: -15 }}>{phraseGender}</p>
                          </div>
                        </>
                      )}
                    </PieChartPayments>
                  </Col>
                  <Col lg="8" md="6" sm="6">
                    <BarChartDiv style={{ marginTop: 10 }}>
                      <HeaderLegend>Idade de Seus Clientes</HeaderLegend>
                      {ageLoading ? (
                        <Loader height="calc(100% - 45px)" />
                      ) : (
                        <BarChart
                          data={{
                            labels: [
                              "0 - 9",
                              "10 - 19",
                              "20 - 29",
                              "30 - 39",
                              "40 - 49",
                              "50 - 59",
                              "60 - 69",
                              "70 - 79",
                              "80 - 89",
                              "90 - 99",
                            ],
                            datasets: [
                              {
                                label: "Clientes",
                                backgroundColor: "#FF2C3A",
                                data: ages,
                                tension: 0.4,
                              },
                            ],
                          }}
                        />
                      )}
                    </BarChartDiv>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <PieChartDiv style={{ height: "400px" }}>
                      <HeaderLegend>Bairros</HeaderLegend>
                      {bairrosLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              width: "60%",
                              margin: "0 auto",
                            }}
                          >
                            <ChartButtons
                              label={graphLabel}
                              onClick={() => {
                                setBairrosData(bairrosClients);
                                setGraphLabel("Clientes");
                              }}
                            >
                              Clientes
                            </ChartButtons>
                            <ChartButtons
                              label={graphLabel}
                              onClick={() => {
                                setBairrosData(bairrosTotal);
                                setGraphLabel("Faturamento");
                              }}
                            >
                              Faturamento
                            </ChartButtons>
                          </div>
                          <BarChart
                            bairro={true}
                            data={{
                              labels: bairrosLabel,
                              datasets: [
                                {
                                  label: graphLabel,
                                  data: bairrosData,
                                  backgroundColor: "#fa5762",
                                  borderColor: "#FF2C3A",
                                  borderWidth: 1,
                                  fill: true,
                                  barThickness: 20,
                                },
                              ],
                            }}
                          />
                        </>
                      )}
                    </PieChartDiv>
                  </Col>
                </Row>
                <Row style={{ minHeight: "430px" }}>
                  <Col>
                    <PieChartDiv loading={funilDataLoading}>
                      <HeaderLegend>Relatório Geral de Clientes</HeaderLegend>
                      {funilDataLoading ? (
                        <Loader text="Essas informações podem demorar um pouco a carregar devido ao alto volume de dados" />
                      ) : (
                        <Row>
                          <Col md="8">
                            <div
                              style={{
                                width: "80%",
                                margin: "auto",
                                fontFamily:
                                  "Montserrat, Helvetica Neue, Arial, sans-serif",
                              }}
                            >
                              <Funnel
                                id="funnel"
                                dataSource={funilData}
                                // dataSource={dataTest}
                                palette={["#01AFFF", "#0CDCE8", "#FFB800"]}
                                argumentField="argument"
                                valueField="value"
                                sortData={false}
                              >
                                <Title text="">
                                  <Margin bottom={30} />
                                </Title>
                                <Tooltip enabled={true} format="fixedPoint" />
                                <Item>
                                  <Border visible={true} />
                                </Item>
                                <Label
                                  visible={true}
                                  position="inside"
                                  backgroundColor="none"
                                  customizeText={formatLabel}
                                />
                              </Funnel>
                            </div>
                          </Col>
                          <Col>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "auto",
                                alignItems: "center",
                                justifyContent: "space-around",
                                height: "100%",
                              }}
                            >
                              <Button
                                className="btn-link"
                                type="button"
                                color="info"
                                style={{ color: "#01AFFF", marginBottom: 50 }}
                                onClick={() => toggle30()}
                              >
                                Relação de Clientes <br />
                                Última Compra: até 30 dias
                              </Button>

                              <Button
                                className="btn-link"
                                type="button"
                                color="success"
                                style={{
                                  color: "#0CDCE8",
                                  marginBottom: 50,
                                  visibility:
                                    modalData60.clients > 0
                                      ? "visible"
                                      : "hidden",
                                }}
                                onClick={() => toggle60()}
                              >
                                Relação de Clientes <br />
                                Última Compra: de 30-60 dias
                              </Button>

                              <Button
                                className="btn-link"
                                color="info"
                                type="button"
                                style={{
                                  color: "#FFB800",
                                  marginBottom: 50,
                                  visibility:
                                    modalData90.clients > 0
                                      ? "visible"
                                      : "hidden",
                                }}
                                onClick={() => toggle90()}
                              >
                                Relação de Clientes <br />
                                Última Compra: de 60-90 dias
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </PieChartDiv>
                  </Col>
                </Row>

                <Modal isOpen={modalFieis} toggle={toggleFieis} size="lg">
                  <ModalBody>
                    <div style={{ textAlign: "center" }}>
                      <h5>Clientes Fiéis</h5>
                      <p>
                        Clientes que fizeram 3 ou mais compras nos últimos 90
                        dias.
                      </p>
                    </div>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th>Telefone</th>
                          <th>Nome</th>
                          <th>Valor Gasto</th>
                          <th>Nº de Visitas</th>
                          <th>{pointsModel ? "Pontos" : "Cashback"}</th>
                          <th className="text-right">Última Visita</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalDataFieis.map((client) => (
                          <tr>
                            <td>{formatPhoneString(client.client.phone)}</td>
                            <td>
                              {client.isClient
                                ? titleize(client.client.name)
                                : "-"}
                            </td>
                            <td>
                              R$
                              {client.client.total.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                              })}
                            </td>
                            <td>{client.client.visits} veze(s)</td>
                            <td>
                              {pointsModel
                                ? `${client.client.points} ponto(s)`
                                : `R$${parseFloat(
                                    client.client.cashback
                                  ).toLocaleString("pt-br", {
                                    minimumFractionDigits: 2,
                                  })}`}
                            </td>
                            <td className="text-right">
                              {formatCompleteDate(client.client.lastPurchase)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>

                <Modal isOpen={modal30} toggle={toggle30} size="lg">
                  <ModalBody>
                    <div style={{ textAlign: "center" }}>
                      <h5>Relação de Clientes</h5>
                      <p>
                        Clientes onde a última compra foi nos últimos 30 dias
                        (Entre{" "}
                        {funilData[0] &&
                          formatDate(funilData[0].parsedStartDate)}{" "}
                        e{" "}
                        {funilData[0] && formatDate(funilData[0].parsedEndDate)}
                        )
                      </p>
                    </div>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th>Telefone</th>
                          <th>Nome</th>
                          <th>Valor Gasto</th>
                          <th>Nº de Visitas</th>
                          <th>{pointsModel ? "Pontos" : "Cashback"}</th>
                          <th className="text-right">Última Visita</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData30.lastPurchases &&
                          modalData30.lastPurchases
                            .sort((a, b) =>
                              a.lastPurchase.localeCompare(b.lastPurchase)
                            )
                            .map((purchase) => (
                              <tr>
                                <td>
                                  <a
                                    href={`https://api.whatsapp.com/send?phone=55${purchase.client.phone
                                      .replace("(", "")
                                      .replace(
                                        ")",
                                        ""
                                      )}&text=${`Olá! Você tem ${
                                      pointsModel
                                        ? `${purchase.client.points} ponto(s)`
                                        : `R$${parseFloat(
                                            purchase.client.cashback
                                          ).toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                          })}`
                                    } de cashback para usar em nosso estabelecimento!`}`}
                                    target="_blank"
                                  >
                                    <AiOutlineWhatsApp size={18} />
                                    {formatPhoneString(purchase.client.phone)}
                                  </a>
                                </td>
                                <td>
                                  {purchase.isClient
                                    ? titleize(purchase.client.name)
                                    : "-"}
                                </td>
                                <td>
                                  R$
                                  {purchase.client.total.toLocaleString(
                                    "pt-br",
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>{purchase.client.visits} veze(s)</td>
                                <td>
                                  {pointsModel
                                    ? `${purchase.client.points} ponto(s)`
                                    : `R$${parseFloat(
                                        purchase.client.cashback
                                      ).toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                      })}`}
                                </td>
                                <td className="text-right">
                                  {formatCompleteDate(purchase.lastPurchase)}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>

                <Modal isOpen={modal60} toggle={toggle60} size="lg">
                  <ModalBody>
                    <div style={{ textAlign: "center" }}>
                      <h5>Relação de Clientes</h5>
                      <p>
                        Clientes onde a última compra foi nos últimos 30 a 60
                        dias (Entre{" "}
                        {funilData[1] &&
                          formatDate(funilData[1].parsedStartDate)}{" "}
                        e{" "}
                        {funilData[1] && formatDate(funilData[1].parsedEndDate)}
                        )
                      </p>
                    </div>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th>Telefone</th>
                          <th>Nome</th>
                          <th>Valor Gasto</th>
                          <th>Nº de Visitas</th>
                          <th>{pointsModel ? "Pontos" : "Cashback"}</th>
                          <th className="text-right">Última Visita</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData60.lastPurchases &&
                          modalData60.lastPurchases
                            .sort((a, b) =>
                              a.lastPurchase.localeCompare(b.lastPurchase)
                            )
                            .map((purchase) => (
                              <tr>
                                <td>
                                  <a
                                    href={`https://api.whatsapp.com/send?phone=55${purchase.client.phone
                                      .replace("(", "")
                                      .replace(
                                        ")",
                                        ""
                                      )}&text=${`Olá! Você tem ${
                                      pointsModel
                                        ? `${purchase.client.points} ponto(s)`
                                        : `R$${parseFloat(
                                            purchase.client.cashback
                                          ).toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                          })}`
                                    } de cashback para usar em nosso estabelecimento!`}`}
                                    target="_blank"
                                  >
                                    <AiOutlineWhatsApp size={18} />
                                    {formatPhoneString(purchase.client.phone)}
                                  </a>
                                </td>
                                <td>
                                  {purchase.isClient
                                    ? titleize(purchase.client.name)
                                    : "-"}
                                </td>
                                <td>
                                  R$
                                  {purchase.client.total.toLocaleString(
                                    "pt-br",
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>{purchase.client.visits} veze(s)</td>
                                <td>
                                  {pointsModel
                                    ? `${purchase.client.points} ponto(s)`
                                    : `R$${parseFloat(
                                        purchase.client.cashback
                                      ).toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                      })}`}
                                </td>
                                <td className="text-right">
                                  {formatCompleteDate(purchase.lastPurchase)}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>

                <Modal isOpen={modal90} toggle={toggle90} size="lg">
                  <ModalBody>
                    <div style={{ textAlign: "center" }}>
                      <h5>Relação de Clientes</h5>
                      <p>
                        Clientes onde a última compra foi nos últimos 60 a 90
                        dias (Entre{" "}
                        {funilData[2] &&
                          formatDate(funilData[2].parsedStartDate)}{" "}
                        e{" "}
                        {funilData[2] && formatDate(funilData[2].parsedEndDate)}
                        )
                      </p>
                    </div>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th>Telefone</th>
                          <th>Nome</th>
                          <th>Valor Gasto</th>
                          <th>Nº de Visitas</th>
                          <th>{pointsModel ? "Pontos" : "Cashback"}</th>
                          <th className="text-right">Última Visita</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData90.lastPurchases &&
                          modalData90.lastPurchases
                            .sort((a, b) =>
                              a.lastPurchase.localeCompare(b.lastPurchase)
                            )
                            .map((purchase) => (
                              <tr>
                                <td>
                                  <a
                                    href={`https://api.whatsapp.com/send?phone=55${purchase.client.phone
                                      .replace("(", "")
                                      .replace(
                                        ")",
                                        ""
                                      )}&text=${`Olá! Você tem ${
                                      pointsModel
                                        ? `${purchase.client.points} ponto(s)`
                                        : `R$${parseFloat(
                                            purchase.client.cashback
                                          ).toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                          })}`
                                    } de cashback para usar em nosso estabelecimento!`}`}
                                    target="_blank"
                                  >
                                    <AiOutlineWhatsApp size={18} />
                                    {formatPhoneString(purchase.client.phone)}
                                  </a>
                                </td>
                                <td>
                                  {purchase.isClient
                                    ? titleize(purchase.client.name)
                                    : "-"}
                                </td>
                                <td>
                                  R$
                                  {purchase.client.total.toLocaleString(
                                    "pt-br",
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>{purchase.client.visits} veze(s)</td>
                                <td>
                                  {pointsModel
                                    ? `${purchase.client.points} ponto(s)`
                                    : `R$${parseFloat(
                                        purchase.client.cashback
                                      ).toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                      })}`}
                                </td>
                                <td className="text-right">
                                  {formatCompleteDate(purchase.lastPurchase)}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>
              </div>
            )}
            {buttonTab === 2 &&
              promotionReport.map((promotion) => (
                <PromotionsReport promotion={promotion} />
              ))}

            {buttonTab === 3 &&
              productReport.map((product) => (
                <ProductsReport product={product} />
              ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Reports;
